<template>
    <registers-pdf :id="$route.params.id"></registers-pdf>
</template>
<script>
import RegistersPdf from '@/components/inspections/registers/pdf/RegistersPdf.vue'

export default {
  name: "RegisterPdfPage",
  components: { RegistersPdf }
};

</script>